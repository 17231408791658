@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents the scroll bar */
}

.App {
  text-align: center;
  position: relative; /* Add this to make absolute positioning relative to .App */
  height: 100vh; /* Use viewport height for full screen */
}

.App-header {
  position: relative; /* Position it absolutely */
  width: 100%; /* Use full width */
  height: 100%;
  top: 0; /* Stick it to the top */
  left: 0; /* Stick it to the left */
  background-color: rgba(0, 0, 0, 0.3); /* Add a background color for clarity */
}

.Canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  image-rendering: auto;
  inset: 0;
  z-index: -1; /* Place it behind other content */
}

.container {
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}

.textContainer {
  font-size: 100%;
  color: white;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  transform: skewy(-15deg);
  width: 100%;
  background-color: #020817;
  padding: 35px;

  /* mobile */
  @media (max-width: 600px) {
    padding: 25px;
  }
  /* mobile landscape */
  @media (max-width: 1000px) and (max-height: 420px) {
    padding: 25px;
  }
}

.frontTextContainer {
  z-index: 5;
  grid-area: 1 / 1 / 2 / 2;
  mix-blend-mode: revert;
  opacity: 0.55;
  box-shadow: 10px 10px 10px black;
}

.backTextContainer {
  z-index: 4;
  grid-area: 1 / 1 / 2 / 2;
  color: white;
  mix-blend-mode: color-burn;
}

h1 {
  text-align: center;
  margin: 0 1em;
  line-height: 1.2;
  font-size: 3em;
  font-weight: 900;
}
